<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 품목명 -->
          <c-text
            name="itemName"
            label="품목명"
            v-model="searchParam.itemName"
          ></c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 지급일 -->
          <c-datepicker
            :range="true"
            name="period2"
            label="지급일"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period2"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            type="search"
            :plantCd="searchParam.plantCd"
            label="지급부서"
            name="deptCd2"
            v-model="searchParam.deptCd2"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 신청일 -->
          <c-datepicker
            :range="true"
            name="period"
            label="신청일"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            type="search"
            :plantCd="searchParam.plantCd"
            label="신청부서"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="보호구 지급 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openNew" />
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'safety-gear',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'requestItems',
            field: 'requestItems',
            label: '지급품목',
            style: 'width:300px',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'giveDt',
            field: 'giveDt',
            label: '지급일',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'giveDeptName',
            field: 'giveDeptName',
            label: '지급부서',
            style: 'width:180px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'giveUserName',
            field: 'giveUserName',
            label: '불출담당자',
            style: 'width:200px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'requestDeptName',
            field: 'requestDeptName',
            label: '신청부서',
            style: 'width:200px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'requestUserName',
            field: 'requestUserName',
            label: '신청자',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'requestDt',
            field: 'requestDt',
            label: '신청일',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'statusName',
            field: 'statusName',
            label: 'LBLSTEPPROGRESS',
            style: 'width:150px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      typeItems: [
        { code: 'Y', codeName: '전체' },
        { code: 'N', codeName: '일별' },
      ],
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        typeItem: 'Y',
        deptCd: '',
        deptCd2: '',
      },
      period: [],
      period2: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.grid.data = [
        {
          plantName: '사업장1',
          statusName: '지급완료',
          requestDt: '2022-09-02',
          requestDeptName: '안전환경팀',
          requestUserName: '김길동',
          giveDt: '2022-09-03',
          giveDeptName: '안전환경팀',
          giveUserName: '보호구 지급자',
          requestItems: '안전모, 발등안전화, 방진장갑, 방진마스크, 내전압용 절연복',
        },
        {
          plantName: '사업장1',
          statusName: '지급처리중',
          requestDt: '2022-03-08',
          requestDeptName: '안전환경팀',
          requestUserName: '김길동',
          giveDt: '2022-03-12',
          giveDeptName: '안전환경팀',
          giveUserName: '보호구 지급자',
          requestItems: '전동식 호흡 보호구, 송기마스크',
        },
        {
          plantName: '사업장1',
          statusName: '지급처리중',
          requestDt: '2022-11-11',
          requestDeptName: '안전환경팀',
          requestUserName: '김길동',
          giveDt: '2022-11-11',
          giveDeptName: '안전환경팀',
          giveUserName: '보호구 지급자',
          requestItems: '안전모',
        },
        {
          plantName: '사업장1',
          statusName: '지급처리중',
          requestDt: '2022-12-25',
          requestDeptName: '안전환경팀',
          requestUserName: '김길동',
          giveDt: '2022-12-30',
          giveDeptName: '안전환경팀',
          giveUserName: '보호구 지급자',
          requestItems: '안전화, 안전장갑, 방진마스크, 안전모',
        },
        {
          plantName: '사업장1',
          statusName: '신청완료',
          requestDt: '2022-01-22',
          requestDeptName: '안전환경팀',
          requestUserName: '김길동',
          giveDt: '2022-01-22',
          giveDeptName: '안전환경팀',
          giveUserName: '보호구 지급자',
          requestItems: '안전화, 안전장갑, 방진마스크, 안전모',
        },
        {
          plantName: '사업장1',
          statusName: '신청완료',
          requestDt: '2022-08-09',
          requestDeptName: '안전환경팀',
          requestUserName: '김길동',
          giveDt: '2022-12-30',
          giveDeptName: '안전환경팀',
          giveUserName: '보호구 지급자',
          requestItems: '안전모, 안전화, 안전장갑',
        },
      ]
    },
    openNew() {
      this.linkClick({giveDt:'',safetySuppliesGiveId:'',plantCd:''});
    },
    linkClick(row, col, index) {
      this.popupOptions.title = '보호구 지급 상세'; // 
      this.popupOptions.param = {
        safetySuppliesGiveId: row.safetySuppliesGiveId ? row.safetySuppliesGiveId : '',
      };
      this.popupOptions.target = () => import(`${'./safetyGearGiveDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
